export class Popupator{
    constructor() {
        this.checkPopupsAndActors();
    }

    checkPopupsAndActors(){
        for(let actor of document.querySelectorAll("[popupator-trigger]"))
            if(actor.hasAttribute("popupator-trigger"))
                this.addFunctionToActor(actor,actor.getAttribute("popupator-trigger"));

        for(let popup of document.querySelectorAll("[popupator]"))
            this.addTriggersToPopup(popup);
    }

    addFunctionToActor(actor, attribute){

        if(attribute) actor.setAttribute('popupator-trigger', attribute);

        actor.addEventListener("click", (e) => {
            e.preventDefault();
            let popup = document.querySelector("[popupator='"+actor.getAttribute("popupator-trigger")+"']");
            if(popup) {
                popup.classList.add("active");
                document.documentElement.style.overflowY = "hidden";
            }
        })
    }

    addTriggersToPopup(popup){
        popup.addEventListener("click", function(e){
            if(e.target === popup){
                document.documentElement.style.overflowY = "";
                popup.classList.remove("active");
            }
        })
        let actions = popup.querySelectorAll("[popupator-action]");
        for(let action of actions){
            if(action.getAttribute("popupator-action").indexOf("close") === 0){
                action.addEventListener("click",function(){
                    document.documentElement.style.overflowY = "";
                    popup.classList.remove("active");
                })
            }
        }
    }
}